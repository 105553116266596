import React, { Component } from 'react';
import { MDBSpinner } from 'mdb-react-ui-kit';
import MainContent from '../main-content/MainContent';
import HeaderContent from '../header/HeaderContent';
import ErrorPage from '../error-boundary/ErrorPage';
import { withOktaAuth } from '@okta/okta-react';
import scripterService from '../services/service';
import * as Constants from '../constants/constants';
import { BrowserRouter as Router, withRouter } from "react-router-dom";
import IdleTimer from 'react-idle-timer'
import { UserContext } from '../contexts/UserContext';
import './Home.scss';

export default withRouter(
  withOktaAuth(class Home extends Component {

    constructor(props) {
      super(props);
      if (this.props.authState.accessToken == undefined)
        this.props.oktaAuth.signOut('/');
      this.idleTimer = null
      this.onIdle = this._onIdle.bind(this);
      this.state = {
        isValidUser: true,
        isLoading: true,
        isLoggedIn: false,
        user_name: null,
        user_full_name: null,
        user_profile: null,
        user_profile_okta: null,
        errorId: 0,
        errorDesc: '',
        active_tenant: {
          tenant_id: null,
          tenant_name: null,
          division_id: null,
          tenants: [],
          divisions: [],
          permissions: {
            //canViewSubmissions: false,
            canViewConfidentialSubmissions: false,
            canEditSubmissionAnytime:false,
            canViewNonConfidentialSubmissions: false,
            canCreateSubmissions: false,
            canDoAnySubmissionUpdate: false,
            canDoOwnSubmissionUpdate: false,
            canDeleteSubmission: false,
            canDoCoverageOperation: false,
            canDeleteProject: false,
            canViewProjects: false,
            canAssignableToAProject: false,
            canViewConfidentialProject: false,
            canViewOrModifyProjectWga: false,
            canViewProjectDeals: false,
            canViewDeal: false,
            canModifyDeal: false,
            canDeleteDeal: false,
            canViewWeekendRead: false,
            canModifyWeekendRead: false,
            canViewTalentRepAgencies: false,
            canCreateTalentRepsAgency: false,
            canEditTalentRepAgencies: false,
            canModifyAgencies: false,
            canGetAssignedToSubmissionAndSubmitCoverage: false,
            canModifyProject: false,
            canDeleteTalentRepAgencies: false,
            canAccessURM: false,
            canManageLookups: false,
            // canAddRemoveViewPrivateProjectFiles:false,
            // canAddRemoveViewPrivateSubmissionFiles:false
           canAddPrivateProjectFiles:false,
           canRemovePrivateProjectFiles:false,
           canViewPrivateProjectFiles:false,
           canAddPrivateSubmissionFiles:false,
           canRemovePrivateSubmissionFiles:false,
           canViewPrivateSubmissionFiles:false
          },
          projects: [],
          submissions: []
        },
        active_route: '/'
      }
    }

    _onIdle(e) {
      this.props.oktaAuth.signOut('/');
      this.setState({
        isLoggedOut: true,
        errorId: '440',
        errorDesc: 'Session is timed out. Please login again!'
      });

    }

    async componentDidMount() {
      try {
        this._isMounted = true;
        await this.props.oktaAuth.getUser()
          .then(response => {
            scripterService.getData(Constants.scripterServiceBaseUrl + '/loggedInUser', 0)
              .then(response => {
                console.log("loggedInUser",response);
                if (this._isMounted) {
                  if (response && response?.data && response?.data?.length > 0) {
                    let userProfile = response?.data?.[0];
                    localStorage.setItem('user_full_name', userProfile?.user_name);
                    var is_active_user = userProfile?.is_active;
                    if (!is_active_user) {
                      this.setState({
                        isValidUser: false,
                        isLoggedIn: false,
                        isLoading: false,
                        errorId: '401',
                        errorDesc: 'User is marked as inactive in the system. Please contact admin for SSCRIPTER application'
                      });
                    }
                    else {
                      this.setState({
                        isValidUser: true,
                        isLoading: false,
                        isLoggedIn: true,
                        user_name: userProfile.name,
                        user_profile: userProfile
                      });
                    }
                    this.processPermissions(userProfile);
                  }
                  else {
                    this.setState({
                      isValidUser: false,
                      isLoggedIn: false,
                      isLoading: false,
                      errorId: '401',
                      errorDesc: 'User is not available in the system or marked as inactive. Please contact admin for SCRIPTER application'
                    });
                  }
                }
              },
                (err) => {
                  console.log('Service Error : Log In -> Getting User Profile ' + err);
                  this.setState({
                    isValidUser: false,
                    isLoggedIn: false,
                    isLoading: false,
                    errorId: '503',
                    errorDesc: 'User profile service is not available. Please try again and contact admin for SCRIPTER application'
                  });
                });


          },
            (err) => {
              console.log(err);
              this.setState({
                isValidUser: false,
                isLoggedIn: false,
                isLoading: false,
                errorId: '503',
                errorDesc: 'OKTA user profile service is not available. Please try again and contact admin for SCRIPTER application'
              });
            });
      }
      catch (err) { }
    }

    processPermissions(userProfile) {
      if (userProfile) {
        let active_tenant = JSON.parse(JSON.stringify({ ...this.state.active_tenant }));
        active_tenant.tenants = userProfile.tenants;
        active_tenant.tenant_id = userProfile.tenants[0]?.tenant_id || null;
        active_tenant.tenant_name = userProfile.tenants[0]?.tenant_name || null;
        active_tenant.divisions = userProfile.divisions;
        active_tenant.projects = userProfile.projects;
        active_tenant.submissions = userProfile.submissions;

        var active_division_id = localStorage.getItem("active_division_id");

        if (active_division_id == null) {
          active_tenant.division_id = userProfile.divisions[0]?.division_id || null;
          localStorage.setItem("active_division_id", userProfile.divisions[0]?.division_id || null);
        } else {
          active_tenant.division_id = parseInt(active_division_id, 10) || 0;
        }
        // if (active_tenant.division_id) {
        //   active_tenant.division_id = userProfile.divisions[0]?.division_id || null;
        // }

        this.processPermissionsforTenant(userProfile, active_tenant);


      }
    }

    processPermissionsforTenant(userProfile, activeTenant) {
      let active_tenant = JSON.parse(JSON.stringify({ ...activeTenant }));
      let permissions = active_tenant?.permissions;
      userProfile.permissions.map((permission) => {
        if (permission?.permission_name) {
          switch (permission?.permission_name) {
            //case 'View Any Submissions': permissions.canViewSubmissions = true; break;
            case 'View Any Non-Confidential Submissions': permissions.canViewNonConfidentialSubmissions = true; break;

            case 'View Any Confidential Submissions': permissions.canViewConfidentialSubmissions = true; break;

            case 'Create Submission': permissions.canCreateSubmissions = true; break;
            case 'Edit Submission Anytime': permissions.canEditSubmissionAnytime = true; break;
            case 'Edit/Submit/Cancel Any Submission': permissions.canDoAnySubmissionUpdate = true; break;
            case 'Edit/Submit/Cancel own Submissions': permissions.canDoOwnSubmissionUpdate = true; break;
            case 'Delete any submission': permissions.canDeleteSubmission = true; break;
            case 'Assign/Revise/Confirm/Cancel Coverages & Confirm Submission': permissions.canDoCoverageOperation = true; break;
            case 'Assignable (and view submission)/Submit Coverages': permissions.canGetAssignedToSubmissionAndSubmitCoverage = true; break;
            case 'Create/Edit Any Project': permissions.canModifyProject = true; break;
            case 'Delete any Project': permissions.canDeleteProject = true; break;
            case 'View Any Non-Confidential Projects': permissions.canViewProjects = true; break;
            case 'Assignable (and can view) a project': permissions.canAssignableToAProject = true; break;

            case 'View Any Confidential Project': permissions.canViewConfidentialProject = true; break;
            //case 'View any project': permissions.canViewConfidentialProject = true; break;

            case "View/Create/Edit/Delete a Project's WGAs": permissions.canViewOrModifyProjectWga = true; break;
            case "View a Project's Deals": permissions.canViewProjectDeals = true; break;
            case 'View Any Deal': permissions.canViewDeal = true; break;
            case 'Create/Edit Any Deal': permissions.canModifyDeal = true; break;
            case 'Delete a Deal': permissions.canDeleteDeal = true; break;
            case 'Create/Edit/Delete Weekend Reads': permissions.canModifyWeekendRead = true; break;
            case 'View Any Weekend Read': permissions.canViewWeekendRead = true; break;
            case 'View Talent/Reps/Agencies': permissions.canViewTalentRepAgencies = true; break;
            case "Create Talent, Reps, or Agencies": permissions.canCreateTalentRepsAgency = true; break;
            case "Edit Talent, Reps, or Agencies": permissions.canEditTalentRepAgencies = true; break;
            case 'Create/Edit Agencies': permissions.canModifyAgencies = true; break;
            case 'Delete Talent, Reps, or Agencies': permissions.canDeleteTalentRepAgencies = true; break;
            case 'Access to URM': permissions.canAccessURM = true; break;
            case 'Manage Lookups': permissions.canManageLookups = true; break;
            // case 'Add/Remove/View Private Project Files' : permissions.canAddRemoveViewPrivateProjectFiles = true;break;
            // case 'Add/Remove/View Private Submission Files' : permissions.canAddRemoveViewPrivateSubmissionFiles = true;break;
            case 'Add Private Submission Files': permissions.canAddPrivateSubmissionFiles = true; break;
            case 'Remove Private Submission Files': permissions.canRemovePrivateSubmissionFiles = true; break;
            case 'View Private Submission Files' : permissions.canViewPrivateSubmissionFiles = true; break;
            case 'Add Private Project Files' : permissions.canAddPrivateProjectFiles = true; break;
            case 'Remove Private Project Files' : permissions.canRemovePrivateProjectFiles = true; break;
            case 'View Private Project Files': permissions.canViewPrivateProjectFiles = true; break;
          }
        }
      });
      active_tenant.permissions = permissions;
      this.setState({ active_tenant: active_tenant });
    }

    componentWillUnmount() {
      this._isMounted = false;
    }

    handleSideNavActive = (value, routePath) => {
      this.mainContent.current.handleSideNavActive1(value, routePath);
    }

    handleSideNavActive1 = (value, routePath) => {
      this.mainContent.current.handleSideNavActive1(value, routePath);
    }

    switchTenant = (divisionId) => {
      let active_tenant = JSON.parse(JSON.stringify({ ...this.state.active_tenant }));
      active_tenant.division_id = divisionId;
      localStorage.setItem("active_division_id", divisionId);
      this.setState({ active_tenant: active_tenant }, () => {
        this.processPermissionsforTenant(this.state.user_profile, this.state.active_tenant);
        let { pathname } = this.props.location;
        console.log(pathname);
        if (pathname === "/") {
          this.props?.history.replace('/reload');
          setTimeout(() => {
            this.props?.history.replace('/');
          });
        }
        else {
          this.props.history.push('/', this.state);
        }


      })
    }

    render() {
      return (
        (this.state.isLoading ?

          <div className='alignCenter '>
            <div className="align-center-vertical">
              <MDBSpinner role='status' color="primary">
              </MDBSpinner>
              <div className="scripter-loader">
                <span style={{ textAlign: 'center' }}>Loading SCRIPTER....</span>
              </div>
            </div>
          </div>

          : (
            !this.state.isValidUser ?
              <ErrorPage isLoggedIn={this.state.isValidUser} errorId={this.state.errorId}
                errorDesc={this.state.errorDesc} ></ErrorPage>
              :

              <>
                {this.state?.active_tenant?.tenant_id && <>

                  <IdleTimer
                    ref={ref => { this.idleTimer = ref }}
                    element={document}
                    onIdle={this.onIdle}
                    debounce={250}
                    timeout={1000 * 60 * Constants.IDLE_TIMEOUT} />
                  <UserContext.Provider value={{
                    user_profile: this.state.user_profile,
                    active_tenant: this.state.active_tenant,
                    switchTenant: this.switchTenant.bind(this)
                  }}>
                    <Router>
                    <div className="h-100">
                      <HeaderContent {...this.state} {...this.props}
                        authState={this.props.authState} switchTenant={this.switchTenant.bind(this)} />
                      <MainContent {...this.state} {...this.props}
                        {...this.functions}
                        wrappedComponentRef={this.mainContent}
                        switchTenant={this.switchTenant.bind(this)} />
                    </div>
                    </Router>
                  </UserContext.Provider>
                </>}
              </>
          )
        )
      );
    }
  }
  )
);
