import React from 'react';
import { MDBContainer, MDBRow, MDBCol, MDBIcon } from "mdbreact";
import BasicLabel from '../../../SharedComponents/BasicLabel/BasicLabel';
import BasicButton from '../../../SharedComponents/BasicButton/BasicButton';
import MessageModal from '../../../SharedComponents/MessageModal';
import { withUserContext } from '../../../../contexts/UserContext';
import ScripterService from '../../../../services/service';
import * as Constants from '../../../../constants/constants';
import BasicCheckbox from '../../../SharedComponents/BasicCheckbox/BasicCheckbox';
import Tooltip from '@material-ui/core/Tooltip';

class SubAttachedFiles extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            fileList: props?.attachedModuleFiles
        }
    }

    componentDidMount() {
        this.props.fileRef.current = {
            onFileUpload: this.onFileUpload.bind(this),
            deleteFile: this.deleteFile.bind(this)
        }
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevProps?.attachedModuleFiles != this.props?.attachedModuleFiles) {
            this.setState({ fileList: this.props?.attachedModuleFiles })
        }
    }

    getFiles() {
        let payload = { ...this.props?.filePayload, tenantName: this.props?.userContext?.active_tenant?.tenant_name, fileName: null }
        this.setState({ showLoader: true })
        ScripterService.getDataParams(Constants.scripterServiceBaseUrl + `/getFiles`, payload, this.props?.userContext?.active_tenant?.tenant_id)
            .then(response => {
                console.log(response);
                this.setState({ showLoader: false })
                if (response.data?.error) {
                    console.log("Error from fetch file", response.data?.error)
                    return;
                }
                let formattedList = response.data?.Contents?.map((file) => {
                    return {
                        "name": file.Key,
                        "url": file.url,
                        "isDownloadable": true
                    }
                })
                this.setState({ fileList: formattedList })
            }, err => {
                console.log('from misc files', err)
                this.setState({ showLoader: false })
            })
    }

    onFileUpload = async (file, moduleId) => {
        let payload = { ...this.props?.filePayload }
        if (payload?.module === "project") {
            payload.projectId = moduleId;
        } else if(payload?.module === "submission"){
            payload.submissionId = moduleId;
        }
        payload.tenantName = this.props?.userContext?.active_tenant?.tenant_name;
        payload.fileName = file?.name;
        payload.isPrivate = file?.isPrivate ? file?.isPrivate : 0;
        return await this.uploadFile(payload, file)
    }

    async uploadFile(payload, file) {
         return await ScripterService.postDataParams(Constants.scripterServiceBaseUrl + `/uploadFile`, payload, this.props?.userContext?.active_tenant?.tenant_id)
            .then(async response => {
                return await ScripterService.uploadFile(response.data.data, file).then(response => {
                    console.log("file upload response", response)      
                                  
                }, err => {
                    console.log('Error from upload file', err)
                })
            }, err => {
                console.log('Error from presigned url', err)
            })
    }

    async deleteFile(name) {
        let payload = { ...this.props?.filePayload, fileName: name, tenantName: this.props?.userContext?.active_tenant?.tenant_name }
      return await ScripterService.deleteDataParams(Constants.scripterServiceBaseUrl + `/deleteFiles`, payload, this.props?.userContext?.active_tenant?.tenant_id)
            .then(response => {
                if (response.data.error) {
                    console.log("error", response.data.error)
                    return;
                }                
            //    this.props?.toggleRefreshPage();
            }, err => {
                console.log('Error from presigned url', err)
            })
    }

    downloadFile = (name) => {
        let url = this.state?.fileList?.filter((obj) => obj.name === name)?.[0].url;
        if (url) {
            fetch(url).then(response => response.blob()).then(blob => {
                const link = document.createElement('a');
                link.href = window.URL.createObjectURL(blob);
                link.setAttribute('download', name);
                document.body.appendChild(link);
                link.click();
                link.remove();
            })
        }
    }

    handleDeleteConfirmation = (file, isDeleteFile) => {
        this.setState({
            showDeleteConfirmationModal: true,
            file: file,
            isDeleteFile: isDeleteFile
        })
    }

    handleConfirm = () => {
        this.setState({ showDeleteConfirmationModal: false }, () => {
            this.props?.handleFileDelete(this.state.file, this.state.isDeleteFile)
        })
    }

    render() {
        // let canAddRemoveViewPrivateSubmissionFiles =this.props?.userContext?.active_tenant?.permissions?.canAddRemoveViewPrivateSubmissionFiles === true?true:false
        let canAddPrivateSubmissionFiles = this.props?.userContext?.active_tenant?.permissions?.canAddPrivateSubmissionFiles === true ? true : false
        let canViewPrivateSubmissionFiles = this.props?.userContext?.active_tenant?.permissions?.canViewPrivateSubmissionFiles === true ? true : false
        let canRemovePrivateSubmissionFiles = this.props?.userContext?.active_tenant?.permissions?.canRemovePrivateSubmissionFiles === true ? true : false
        let canAddPrivateProjectFiles = this.props?.userContext?.active_tenant?.permissions?.canAddPrivateProjectFiles === true ? true : false
        let canViewPrivateProjectFiles = this.props?.userContext?.active_tenant?.permissions?.canViewPrivateProjectFiles === true ? true : false
        let canRemovePrivateProjectFiles = this.props?.userContext?.active_tenant?.permissions?.canRemovePrivateProjectFiles === true ? true : false
        return (
            <MDBContainer fluid className="sub-attached-files-container">
                <MessageModal
                    open={this.state.showDeleteConfirmationModal}
                    title={"Delete file"}
                    message={"Would you like to delete saved file?"}
                    primaryButtonText={"Delete"}
                    secondaryButtonText={"Cancel"}
                    onConfirm={this.handleConfirm}
                    handleClose={(e) => this.setState({ showDeleteConfirmationModal: false })}
                />
                <MDBRow className="file-label-section">
                    <MDBCol className="flex">
                        <BasicLabel type={"darkFieldHeading"} text={"Attached Files"} />
                        <span className="file-attach-btn">
                            <BasicButton
                                id={this.props?.id}
                                type="inline"
                                color="primary"
                                text={" "}
                                icon={"plus-circle"}
                                uploadFile={(file, attachedFiles) => this.props?.handleFileUpload(file, attachedFiles)}
                                inputType='file'
                            />
                        </span>
                        <Tooltip 
                            title="Attached file is viewable only to admin and Submitted To recipient(s)" 
                            placement="right-start"
                        >
                            <div className="file-info-circle">
                                <MDBIcon icon={"info-circle"} size="sm" />
                            </div>
                        </Tooltip>
                    </MDBCol>
                </MDBRow>
                {this.state.fileList?.map((file, index) => (
                    <MDBRow>
                        <MDBCol md={5}>
                            <div className="flex">
                                <BasicLabel type={"text"} text={file?.name} />
                                {(this.props?.mode === "Edit" && file?.isDownloadable) ?
                                    (file.type === 'submission' && canViewPrivateSubmissionFiles && file?.isPrivate === "1")?
                                    <MDBIcon
                                        icon="file"
                                        size='sm'
                                        onClick={() => this.downloadFile(file?.name)}
                                    />
                                    :(file.type === 'project' && canViewPrivateProjectFiles && file?.isPrivate === "1")?
                                    <MDBIcon
                                        icon="file"
                                        size='sm'
                                        onClick={() => this.downloadFile(file?.name)}
                                    />: (file?.isPrivate === "0")?
                                    <MDBIcon
                                       icon="file"
                                       size='sm'
                                       onClick={() => this.downloadFile(file?.name)}
                                   />:null
                                    :""
                                }
                            </div>
                        </MDBCol>
                        <MDBCol md={3}>
                        {(file?.type == 'submission' && (canAddPrivateSubmissionFiles && canRemovePrivateSubmissionFiles) ) ?
                        // Add and remove submission is there then able to check or uncheck
                                <BasicCheckbox
                                label="Private"
                                id={`private-${index}`}
                                checked={file?.isPrivate ? parseInt(file?.isPrivate) : 0}
                                onChange={(e) =>  this.props?.handleFilePrivate(file, e.target.checked ? 1 : 0, index)}/>:
                             (file?.type === 'submission' && canAddPrivateSubmissionFiles && !canRemovePrivateSubmissionFiles && file?.isPrivate === "0")?
                            //  Add permission is there and remove is not then only show uncheck box and user can cable to check it
                               <BasicCheckbox
                                label="Private"
                                id={`private-${index}`}
                                checked={file?.isPrivate ? parseInt(file?.isPrivate) : 0}
                                onChange={(e) =>  this.props?.handleFilePrivate(file, 1, index)}/>: 
                             (file?.type === 'submission' && !canAddPrivateSubmissionFiles && canRemovePrivateSubmissionFiles && file?.isPrivate === "1")?
                            //  Add permission is not there but remove is there then only show the check box and user able to uncheck it
                             <BasicCheckbox
                             label="Private"
                             id={`private-${index}`}
                             checked={file?.isPrivate ? parseInt(file?.isPrivate) : 0}
                             onChange={(e) =>  this.props?.handleFilePrivate(file, 0, index)}/>: null
                        } 
                        {(file?.type === 'submission' && canViewPrivateSubmissionFiles && !canAddPrivateSubmissionFiles && !canRemovePrivateSubmissionFiles)?
                        // only view permission are then show the check box in there state user not able to check or uncheck it
                        <BasicCheckbox
                        label="Private"
                        id={`private-${index}`}
                        checked={file?.isPrivate ? parseInt(file?.isPrivate) : 0}/>: null
                        }
                        {(file?.type == 'project' && (canAddPrivateProjectFiles && canRemovePrivateProjectFiles))?
                        // Add and remove project is there then able to check or uncheck
                            <BasicCheckbox
                            label="Private"
                            id={`private-${index}`}
                            checked={file?.isPrivate ? parseInt(file?.isPrivate) : 0}
                            onChange={(e) =>  this.props?.handleFilePrivate(file, e.target.checked ? 1 : 0, index)} />: 
                         (file?.type == 'project' && canAddPrivateProjectFiles && !canRemovePrivateProjectFiles && file?.isPrivate === "0")?
                         //  Add permission is there and remove is not then only show uncheck box and user can cable to check it
                          <BasicCheckbox
                            label="Private"
                            id={`private-${index}`}
                            checked={file?.isPrivate ? parseInt(file?.isPrivate) : 0}
                            onChange={(e) =>  this.props?.handleFilePrivate(file, 1, index)} />:
                        (file?.type == 'project' && !canAddPrivateProjectFiles && canRemovePrivateProjectFiles && file?.isPrivate === "1")?
                        //  Add permission is not there but remove is there then only show the check box and user able to uncheck it
                            <BasicCheckbox
                            label="Private"
                            id={`private-${index}`}
                            checked={file?.isPrivate ? parseInt(file?.isPrivate) : 0}
                            onChange={(e) =>  this.props?.handleFilePrivate(file, 0, index)} />:null 
                        }
                        {(file?.type === 'project' && canViewPrivateProjectFiles && !canAddPrivateProjectFiles && !canRemovePrivateProjectFiles)?
                        // only view permission are then show the check box in there state user not able to check or uncheck it
                        <BasicCheckbox
                        label="Private"
                        id={`private-${index}`}
                        checked={file?.isPrivate ? parseInt(file?.isPrivate) : 0}/>: null
                        }
                        </MDBCol>
                        {/* Delete button */}
                        <MDBCol md={3}>
                        {(file?.type != 'project' && file?.type != 'submission')?
                        <MDBIcon
                        icon="trash-alt"
                        size='sm'
                        onClick={() => 
                        this.props?.handleFileDelete(file, true)}/>:undefined
                        }
                        {(file?.type == 'submission' && file?.isPrivate === '0' && !canRemovePrivateSubmissionFiles)?
                        <MDBIcon
                          icon="trash-alt"
                          size='sm'
                          onClick={() => this.handleDeleteConfirmation(file, true) }/>:
                          (file?.type == 'submission' && canRemovePrivateSubmissionFiles)?
                          <MDBIcon
                          icon="trash-alt"
                          size='sm'
                          onClick={() => this.handleDeleteConfirmation(file, true) }/>:undefined
                        }   
                        {(file?.type == 'project' && file?.isPrivate === '0' && !canRemovePrivateProjectFiles)?
                        <MDBIcon
                          icon="trash-alt"
                          size='sm'
                          onClick={() => this.handleDeleteConfirmation(file, true) }/>:
                          (file?.type == 'project' && canRemovePrivateProjectFiles)?
                          <MDBIcon
                          icon="trash-alt"
                          size='sm'
                          onClick={() => this.handleDeleteConfirmation(file, true) }/>:undefined
                        } 
                        </MDBCol>
                    </MDBRow>
                ))}
            </MDBContainer>
        );
    }
}

export default withUserContext(SubAttachedFiles)